// src/components/Schedules.js

import React, { useState, useEffect, useMemo } from 'react';
import {
  Container,
  Typography,
  TextField,
  MenuItem,
  Box,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import moment from 'moment-timezone';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TaskIcon from '@mui/icons-material/Assignment';
import ScheduleIcon from '@mui/icons-material/Event';
import ErrorIcon from '@mui/icons-material/Error';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ReplayIcon from '@mui/icons-material/Replay';
import RefreshIcon from '@mui/icons-material/Refresh';
import UserAutocomplete from './UserAutocomplete';

function Schedules({ handleTabChange }) {
  const [schedules, setSchedules] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [users, setUsers] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const [loadingSchedules, setLoadingSchedules] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [attemptsFilter, setAttemptsFilter] = useState('');
  const [taskFilter, setTaskFilter] = useState('');

  const [successMessage, setSuccessMessage] = useState('');

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [newDateTime, setNewDateTime] = useState(null);

  const [selectedUserForAdd, setSelectedUserForAdd] = useState('');
  const [selectedTaskForAdd, setSelectedTaskForAdd] = useState('');
  const [addDateTime, setAddDateTime] = useState(null);
  const [tasksForSelectedUser, setTasksForSelectedUser] = useState([]);
  const [userTimezone, setUserTimezone] = useState('UTC');

  const [currentView, setCurrentView] = useState('Upcoming');
  const [currentDate, setCurrentDate] = useState(moment());

  const [userGroups, setUserGroups] = useState([]);
  const [selectedUserGroup, setSelectedUserGroup] = useState('');

  const API_BASE_URL = 'https://bonushealth.co/api';

  useEffect(() => {
    fetchData();
    fetchUserGroups();
  }, []);

  useEffect(() => {
    const now = Date.now();
    const thirtyMinutes = 30 * 60 * 1000;

    const storedStatusFilter = localStorage.getItem('statusFilter');
    if (storedStatusFilter) {
      const { value, timestamp } = JSON.parse(storedStatusFilter);
      if (now - timestamp < thirtyMinutes) {
        setStatusFilter(value);
      } else {
        localStorage.removeItem('statusFilter');
      }
    }

    const storedAttemptsFilter = localStorage.getItem('attemptsFilter');
    if (storedAttemptsFilter) {
      const { value, timestamp } = JSON.parse(storedAttemptsFilter);
      if (now - timestamp < thirtyMinutes) {
        setAttemptsFilter(value);
      } else {
        localStorage.removeItem('attemptsFilter');
      }
    }

    const storedTaskFilter = localStorage.getItem('taskFilter');
    if (storedTaskFilter) {
      const { value, timestamp } = JSON.parse(storedTaskFilter);
      if (now - timestamp < thirtyMinutes) {
        setTaskFilter(value);
      } else {
        localStorage.removeItem('taskFilter');
      }
    }

    const storedSelectedUserGroup = localStorage.getItem('selectedUserGroup');
    if (storedSelectedUserGroup) {
      const { value, timestamp } = JSON.parse(storedSelectedUserGroup);
      if (now - timestamp < thirtyMinutes) {
        setSelectedUserGroup(value);
      } else {
        localStorage.removeItem('selectedUserGroup');
      }
    }
  }, []);

  const fetchData = async () => {
    setLoadingSchedules(true);
    try {
      const [schedulesResponse, tasksResponse, usersResponse] = await Promise.all([
        fetch(`${API_BASE_URL}/sget/schedule`),
        fetch(`${API_BASE_URL}/sget/tasks`),
        fetch(`${API_BASE_URL}/sget/users`),
      ]);

      const [schedulesData, tasksData, usersData] = await Promise.all([
        schedulesResponse.json(),
        tasksResponse.json(),
        usersResponse.json(),
      ]);

      if (schedulesData.success && tasksData.success && usersData.success) {
        const schedules = schedulesData.schedule || [];
        const tasks = tasksData.tasks || [];
        const users = usersData.users || [];

        setSchedules(schedules);
        setTasks(tasks);
        setUsers(users);

        const tasksById = {};
        tasks.forEach((task) => {
          tasksById[task.id] = task;
        });

        const usersById = {};
        users.forEach((user) => {
          usersById[user.id] = user;
        });

        const combinedData = schedules.map((schedule) => {
          const task = tasksById[schedule.task_id] || {};
          const user = usersById[task.subject_id] || {};
          return {
            ...schedule,
            task,
            user,
          };
        });

        setCombinedData(combinedData);
      } else {
        setErrorMessage('Failed to fetch data.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setErrorMessage('An error occurred while fetching data.');
    } finally {
      setLoadingSchedules(false);
    }
  };

  const fetchUserGroups = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/sget/user_groups`);
      const data = await response.json();
      if (data.success) {
        const groups = data.user_groups.map((group) => ({
          ...group,
          user_ids: group.user_ids ? group.user_ids.split(',') : [],
        }));
        setUserGroups(groups || []);
      } else {
        setErrorMessage(data.error || 'Failed to fetch user groups.');
      }
    } catch (err) {
      console.error('Error fetching user groups:', err);
      setErrorMessage('An error occurred while fetching user groups.');
    }
  };

  const utcToEdt = (utcTimestamp) => {
    if (!utcTimestamp) return 'N/A';
    return moment
      .utc(utcTimestamp)
      .tz('America/New_York')
      .format('YYYY-MM-DD hh:mm:ss A z');
  };

  const uniqueStatuses = [...new Set(combinedData.map((item) => item.status))];
  const uniqueAttempts = [...new Set(combinedData.map((item) => item.attempts))].sort(
    (a, b) => a - b
  );
  const uniqueTaskNames = [...new Set(combinedData.map((item) => item.task.name))];

  const filteredCombinedData = combinedData.filter((item) => {
    let statusMatch = true;
    let attemptsMatch = true;
    let channelMatch = true;
    let taskMatch = true;
    if (statusFilter) {
      statusMatch = item.status === statusFilter;
    }
    if (attemptsFilter !== '') {
      attemptsMatch = item.attempts === parseInt(attemptsFilter);
    }
    if (taskFilter) {
      taskMatch = item.task.name === taskFilter;
    }
    channelMatch = item.task.default_channel === 'RetellWrapper';
    return statusMatch && attemptsMatch && channelMatch && taskMatch;
  });

  if (currentView === 'Upcoming') {
    filteredCombinedData.sort((a, b) => new Date(a.datetime) - new Date(b.datetime));
  }

  const handleAddScheduleClick = () => {
    setSelectedUserForAdd('');
    setSelectedTaskForAdd('');
    setAddDateTime(null);
    setTasksForSelectedUser([]);
    setUserTimezone('UTC');
    setAddDialogOpen(true);
  };

  const handleAddScheduleConfirm = async () => {
    if (!selectedUserForAdd || !selectedTaskForAdd || !addDateTime) {
      setErrorMessage('Please select a user, task, and date/time.');
      return;
    }

    try {
      const when = addDateTime.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]');

      const formData = new FormData();
      formData.append('task_id', selectedTaskForAdd);
      formData.append('when', when);

      const url = `${API_BASE_URL}/sadd_schedule`;

      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();

      if (data.success) {
        setSuccessMessage('Schedule added successfully.');
        fetchData();
      } else {
        setErrorMessage(data.error || 'Failed to add schedule.');
      }
    } catch (error) {
      console.error('Error adding schedule:', error);
      setErrorMessage('An error occurred while adding the schedule.');
    } finally {
      setAddDialogOpen(false);
    }
  };

  const handleAddScheduleCancel = () => {
    setAddDialogOpen(false);
  };

  const handleUserChange = (userId) => {
    setSelectedUserForAdd(userId);
    const userTasks = tasks.filter((task) => task.subject_id === userId);
    setTasksForSelectedUser(userTasks);
    setSelectedTaskForAdd('');

    const selectedUser = users.find((user) => user.id === userId);
    const userTz = selectedUser ? selectedUser.timezone : 'UTC';
    setUserTimezone(userTz);

    setAddDateTime(moment.tz(moment(), userTz));
  };

  const handleEditClick = (schedule) => {
    const timezone = (schedule.user && schedule.user.timezone) || 'UTC';
    setSelectedSchedule({ ...schedule, timezone });
    setNewDateTime(moment.tz(schedule.datetime, timezone));
    setEditDialogOpen(true);
  };

  const handleEditConfirm = async () => {
    try {
      if (!newDateTime || !selectedSchedule) {
        setErrorMessage('Please select a date and time.');
        return;
      }
      const scheduleId = selectedSchedule.id;
      const newSchedule = newDateTime.utc().format('YYYY-MM-DDTHH:mm:ss[Z]');

      const url = `${API_BASE_URL}/sedit/schedule`;

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          schedule_id: scheduleId,
          datetime: newSchedule,
        }),
      });

      const data = await response.json();

      if (data.success) {
        setSuccessMessage('Schedule updated successfully.');
        fetchData();
      } else {
        setErrorMessage(data.error || 'Failed to update schedule.');
      }
    } catch (error) {
      console.error('Error updating schedule:', error);
      setErrorMessage('An error occurred while updating the schedule.');
    } finally {
      setEditDialogOpen(false);
      setSelectedSchedule(null);
    }
  };

  const handleEditCancel = () => {
    setEditDialogOpen(false);
    setSelectedSchedule(null);
  };

  const handleDeleteClick = (schedule) => {
    setSelectedSchedule(schedule);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      const scheduleId = selectedSchedule.id;

      const url = `${API_BASE_URL}/sdelete/schedule/${scheduleId}`;

      const response = await fetch(url);
      const data = await response.json();

      if (data.success) {
        setSuccessMessage('Schedule deleted successfully.');
        fetchData();
      } else {
        setErrorMessage(data.error || 'Failed to delete schedule.');
      }
    } catch (error) {
      console.error('Error deleting schedule:', error);
      setErrorMessage('An error occurred while deleting the schedule.');
    } finally {
      setDeleteDialogOpen(false);
      setSelectedSchedule(null);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setSelectedSchedule(null);
  };

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setCurrentView(newView);
    }
  };

  const handlePrevDate = () => {
    setCurrentDate(currentDate.clone().subtract(1, 'day'));
  };

  const handleNextDate = () => {
    setCurrentDate(currentDate.clone().add(1, 'day'));
  };

  const handleClearStaleClick = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/internal/sclean_schedule/`);
      const data = await response.json();
      if (data.success) {
        setSuccessMessage('Stale schedules cleared successfully.');
        fetchData();
      } else {
        setErrorMessage(data.error || 'Failed to clear stale schedules.');
      }
    } catch (error) {
      console.error('Error clearing stale schedules:', error);
      setErrorMessage('An error occurred while clearing stale schedules.');
    }
  };

  const handleSeeTaskClick = (item) => {
    const userId = item.user.id;
    const taskId = item.task_id || item.task.id;
    localStorage.setItem('tasksUserId', userId);
    localStorage.setItem('tasksTaskId', taskId);
    localStorage.setItem('tasksAutoFetch', 'true');

    if (handleTabChange) {
      handleTabChange('Tasks');
    }
  };

  const handleSeeTaskClickFromDaily = (user, task) => {
    localStorage.setItem('tasksUserId', user.id);
    localStorage.setItem('tasksTaskId', task.id);
    localStorage.setItem('tasksAutoFetch', 'true');

    if (handleTabChange) {
      handleTabChange('Tasks');
    }
  };

  const filteredUsers = useMemo(() => {
    if (!selectedUserGroup) {
      return users;
    } else {
      const group = userGroups.find((group) => group.id === selectedUserGroup);
      if (group && group.user_ids.length > 0) {
        return users.filter((user) => group.user_ids.includes(user.id.toString()));
      } else {
        return [];
      }
    }
  }, [selectedUserGroup, users, userGroups]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Schedules
      </Typography>

      {errorMessage && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {errorMessage}
        </Alert>
      )}

      {successMessage && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {successMessage}
        </Alert>
      )}

      <Box sx={{ mt: 2, display: 'flex', gap: 2, alignItems: 'center' }}>
        <ToggleButtonGroup
          value={currentView}
          exclusive
          onChange={handleViewChange}
          aria-label="View Toggle"
        >
          <ToggleButton value="Upcoming" aria-label="Upcoming View">
            Upcoming
          </ToggleButton>
          <ToggleButton value="Daily" aria-label="Daily View">
            Daily
          </ToggleButton>
        </ToggleButtonGroup>

        {currentView === 'Upcoming' && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, ml: 2 }}>
            <TextField
              select
              label="Filter by Status"
              value={statusFilter}
              onChange={(e) => {
                const newValue = e.target.value;
                setStatusFilter(newValue);
                const filterData = { value: newValue, timestamp: Date.now() };
                localStorage.setItem('statusFilter', JSON.stringify(filterData));
              }}
              fullWidth
              sx={{ flexGrow: 1, minWidth: '200px' }}
            >
              <MenuItem value="">
                <em>All Statuses</em>
              </MenuItem>
              {uniqueStatuses.map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              select
              label="Filter by Attempts"
              value={attemptsFilter}
              onChange={(e) => {
                const newValue = e.target.value;
                setAttemptsFilter(newValue);
                const filterData = { value: newValue, timestamp: Date.now() };
                localStorage.setItem('attemptsFilter', JSON.stringify(filterData));
              }}
              fullWidth
              sx={{ flexGrow: 1, minWidth: '200px' }}
            >
              <MenuItem value="">
                <em>All Attempts</em>
              </MenuItem>
              {uniqueAttempts.map((attempt) => (
                <MenuItem key={attempt} value={attempt}>
                  {attempt}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              select
              label="Filter by Task"
              value={taskFilter}
              onChange={(e) => {
                const newValue = e.target.value;
                setTaskFilter(newValue);
                const filterData = { value: newValue, timestamp: Date.now() };
                localStorage.setItem('taskFilter', JSON.stringify(filterData));
              }}
              fullWidth
              sx={{ flexGrow: 1, minWidth: '200px' }}
            >
              <MenuItem value="">
                <em>All Tasks</em>
              </MenuItem>
              {uniqueTaskNames.map((taskName) => (
                <MenuItem key={taskName} value={taskName}>
                  {taskName}
                </MenuItem>
              ))}
            </TextField>

            <IconButton onClick={fetchData} aria-label="Reload Schedules">
              <RefreshIcon />
            </IconButton>
          </Box>
        )}

        {currentView === 'Daily' && (
          <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
            <IconButton onClick={handlePrevDate}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" sx={{ mx: 2 }}>
              {currentDate.format('MM/DD/YYYY')}
            </Typography>
            <IconButton onClick={handleNextDate}>
              <ArrowForwardIcon />
            </IconButton>

            <TextField
              select
              label="Filter by User Group"
              value={selectedUserGroup}
              onChange={(e) => {
                const newValue = e.target.value;
                setSelectedUserGroup(newValue);
                const filterData = { value: newValue, timestamp: Date.now() };
                localStorage.setItem('selectedUserGroup', JSON.stringify(filterData));
              }}
              sx={{ ml: 2, minWidth: 200 }}
            >
              <MenuItem value="">
                <em>All User Groups</em>
              </MenuItem>
              {userGroups.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.name}
                </MenuItem>
              ))}
            </TextField>
            <IconButton onClick={fetchData} aria-label="Reload Schedules">
              <RefreshIcon />
            </IconButton>
          </Box>
        )}
      </Box>

      {loadingSchedules && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      )}

      {/* Upcoming View */}
      {currentView === 'Upcoming' && !loadingSchedules && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" gutterBottom>
            Schedule Results
          </Typography>
          {/* Add Schedule Button */}
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddScheduleClick}
            >
              Add Schedule
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClearStaleClick}
              sx={{ ml: 2 }}
            >
              Clear Stale
            </Button>
          </Box>
          {filteredCombinedData.length > 0 ? (
            <TableContainer component={Paper}>
              <Table aria-label="schedule table">
                <TableHead>
                  <TableRow>
                    <TableCell>User</TableCell>
                    <TableCell>Task Name</TableCell>
                    <TableCell>Schedule Status</TableCell>
                    <TableCell>Datetime (EDT)</TableCell>
                    <TableCell>Created (EDT)</TableCell>
                    <TableCell>Last Update (EDT)</TableCell>
                    <TableCell>Attempts</TableCell>
                    <TableCell>Payload</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredCombinedData.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>
                        {item.user.first_name} {item.user.last_name}
                      </TableCell>
                      <TableCell>{item.task.name || 'N/A'}</TableCell>
                      <TableCell>{item.status}</TableCell>
                      <TableCell>
                        {item.datetime ? utcToEdt(item.datetime) : 'N/A'}
                      </TableCell>
                      <TableCell>
                        {item.created ? utcToEdt(item.created) : 'N/A'}
                      </TableCell>
                      <TableCell>
                        {item.last_update ? utcToEdt(item.last_update) : 'N/A'}
                      </TableCell>
                      <TableCell>{item.attempts}</TableCell>
                      <TableCell>{item.payload ? item.payload : 'N/A'}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => handleEditClick(item)}
                          sx={{ mr: 1 }}
                          disabled={item.status === 'COMPLETED' || item.status === 'CANCELLED'}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          onClick={() => handleDeleteClick(item)}
                        >
                          Delete
                        </Button>
                        {/* Display View button only if item.payload is undefined or null */}
                        {!item.payload && item.task_id && (
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => handleSeeTaskClick(item)}
                            sx={{ ml: 1 }}
                          >
                            View
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="h6" align="center">
              No schedules available.
            </Typography>
          )}
        </Box>
      )}

      {/* Daily View */}
      {currentView === 'Daily' && !loadingSchedules && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" gutterBottom>
            Daily View - {currentDate.format('MM/DD/YYYY')}
          </Typography>
          <List>
            {filteredUsers.map((user) => {
              const userTimeZone = user.timezone || 'America/New_York';
              const userCurrentTime = moment().tz(userTimeZone);
              const userTasks = tasks.filter((task) => task.subject_id === user.id);
              const userSchedules = combinedData.filter((item) => item.user.id === user.id);

              // Check for tasks on current date
              const tasksToday = userTasks.filter((task) => {
                const taskBegin = task.begin_timestamp
                  ? moment.utc(task.begin_timestamp).tz(userTimeZone)
                  : null;
                const taskEnd = task.end_timestamp
                  ? moment.utc(task.end_timestamp).tz(userTimeZone)
                  : null;
                const currentDateInUserTz = currentDate.clone().tz(userTimeZone);

                if (taskBegin && taskEnd && task.default_channel === 'RetellWrapper') {
                  return (
                    currentDateInUserTz.isSame(taskBegin, 'day') &&
                    currentDateInUserTz.isBetween(taskBegin, taskEnd, 'day', '[]')
                  );
                }
                return false;
              });

              // Check for schedules on current date
              const schedulesToday = userSchedules.filter((item) => {
                const scheduleTime = moment.utc(item.datetime).tz(userTimeZone);
                return (
                  scheduleTime.isSame(currentDate.clone().tz(userTimeZone), 'day') &&
                  item.task.default_channel === 'RetellWrapper'
                );
              });

              // Build taskGroups
              const taskGroups = {};

              // Build taskGroups with tasksToday
              tasksToday.forEach((task) => {
                taskGroups[task.id] = { task: task, schedules: [] };
              });

              // Add schedules to taskGroups
              schedulesToday.forEach((schedule) => {
                if (taskGroups[schedule.task_id]) {
                  taskGroups[schedule.task_id].schedules.push(schedule);
                } else {
                  if (schedule.task.default_channel === 'RetellWrapper') {
                    taskGroups[schedule.task_id] = { task: schedule.task, schedules: [schedule] };
                  }
                }
              });

              // Check if past noon in user's timezone
              const isPastNoon = currentDate.clone().tz(userTimeZone).hour() >= 12;

              // Check if task not completed and past noon, and currentDate is today or in the past
              const overdue =
                tasksToday.some((task) => task.state !== 'COMPLETED') &&
                isPastNoon &&
                currentDate.clone().tz(userTimeZone).isSame(moment().tz(userTimeZone), 'day');

              return (
                <ListItem key={user.id} alignItems="flex-start">
                  <ListItemText
                    primary={`${user.first_name} ${user.last_name} ${user.phone_number}`}
                    secondary={
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                        {/* Current Time Icon */}
                        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                          <AccessTimeIcon />
                          <Typography variant="body2" sx={{ ml: 0.5 }}>
                            {userCurrentTime.format('hh:mm A')}
                          </Typography>
                        </Box>

                        {/* Task Groups */}
                        {Object.values(taskGroups).map((group) => (
                          <Box
                            key={group.task.id}
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              border: '1px solid #ccc',
                              borderRadius: 2,
                              p: 2,
                              mr: 2,
                              mb: 2,
                            }}
                          >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <TaskIcon />
                              <Typography variant="body2" sx={{ ml: 0.5 }}>
                                {group.task.name} {group.task.state === 'COMPLETED' ? '✓' : '⏳'}
                              </Typography>
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => handleSeeTaskClickFromDaily(user, group.task)}
                                sx={{ ml: 1 }}
                              >
                                View
                              </Button>
                            </Box>

                            {/* Schedules */}
                            {group.schedules.map((schedule) => {
                              const scheduleTime = moment
                                .utc(schedule.datetime)
                                .tz(userTimeZone)
                                .format('hh:mm A');
                              const attempts = schedule.attempts || 0;
                              const tooManyAttempts = attempts >= 4;

                              return (
                                <Box
                                  key={schedule.id}
                                  sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
                                >
                                  <ScheduleIcon />
                                  <Typography variant="body2" sx={{ ml: 0.5 }}>
                                    {scheduleTime}
                                    {schedule.status === 'COMPLETED' && ' 🎉'}
                                  </Typography>

                                  {/* Attempt Icons */}
                                  {Array.from({ length: attempts }).map((_, idx) => (
                                    <ReplayIcon key={idx} fontSize="small" />
                                  ))}

                                  {/* Too Many Attempts */}
                                  {tooManyAttempts && (
                                    <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                                      <ErrorIcon color="error" fontSize="small" />
                                      <Typography variant="body2" color="error" sx={{ ml: 0.5 }}>
                                        Max call retries
                                      </Typography>
                                    </Box>
                                  )}
                                </Box>
                              );
                            })}
                          </Box>
                        ))}

                        {/* Overdue Icon */}
                        {overdue && (
                          <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                            <ErrorIcon color="error" />
                            <Typography variant="body2" color="error" sx={{ ml: 0.5 }}>
                              Overdue
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        </Box>
      )}

      {/* Add Schedule Dialog */}
      <Dialog open={addDialogOpen} onClose={handleAddScheduleCancel}>
        <DialogTitle>Add Schedule</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select a user, task, and date/time to add a new schedule.
          </DialogContentText>
          <UserAutocomplete
            users={users}
            userId={selectedUserForAdd}
            onUserChange={handleUserChange}
            label="Select User"
            fullWidth
            sx={{ mt: 2 }}
          />
          <TextField
            select
            label="Select Task"
            value={selectedTaskForAdd}
            onChange={(e) => setSelectedTaskForAdd(e.target.value)}
            fullWidth
            sx={{ mt: 2 }}
            disabled={!selectedUserForAdd}
          >
            {tasksForSelectedUser.map((task) => (
              <MenuItem key={task.id} value={task.id}>
                {task.name}
              </MenuItem>
            ))}
          </TextField>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              label="Schedule DateTime"
              value={addDateTime}
              onChange={(newValue) => {
                const adjustedValue = moment.tz(
                  newValue.format('YYYY-MM-DDTHH:mm:ss'),
                  userTimezone
                );
                setAddDateTime(adjustedValue);
              }}
              renderInput={(params) => <TextField {...params} fullWidth sx={{ mt: 2 }} />}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddScheduleCancel}>Cancel</Button>
          <Button onClick={handleAddScheduleConfirm} variant="contained" color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Dialog */}
      <Dialog open={editDialogOpen} onClose={handleEditCancel}>
        <DialogTitle>Edit Schedule</DialogTitle>
        <DialogContent>
          <DialogContentText>Select a new date and time for the schedule.</DialogContentText>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              label="New DateTime"
              value={newDateTime}
              onChange={(newValue) => setNewDateTime(newValue)}
              renderInput={(params) => <TextField {...params} fullWidth sx={{ mt: 2 }} />}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditCancel}>Cancel</Button>
          <Button onClick={handleEditConfirm} variant="contained" color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Dialog */}
      <Dialog open={deleteDialogOpen} onClose={handleDeleteCancel}>
        <DialogTitle>Delete Schedule</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the following schedule?
          </DialogContentText>
          <Typography variant="body1" sx={{ mt: 2 }}>
            <strong>User:</strong> {selectedSchedule?.user?.first_name}{' '}
            {selectedSchedule?.user?.last_name}
          </Typography>
          <Typography variant="body1">
            <strong>Task:</strong> {selectedSchedule?.task?.name || 'N/A'}
          </Typography>
          <Typography variant="body1">
            <strong>Schedule Datetime:</strong>{' '}
            {selectedSchedule?.datetime ? utcToEdt(selectedSchedule.datetime) : 'N/A'}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} variant="contained" color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Schedules;
