// src/components/Messages.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { formatInTimeZone } from 'date-fns-tz';

const API_BASE_URL = 'https://bonushealth.co/api';

const formatDateToEDT = (utcDateStr) => {
  const date = new Date(utcDateStr);
  const timeZone = 'America/New_York';
  return formatInTimeZone(date, timeZone, "yyyy-MM-dd HH:mm 'EDT'");
};

function setWithExpiry(key, value, ttl) {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

function getWithExpiry(key) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

function Messages() {
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');

  const fetchMessages = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/sget/messages`);
      const data = await response.json();
      if (data.success) {
        const sortedMessages = data.messages.sort(
          (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
        );
        setMessages(sortedMessages);
      } else {
        console.error(`Failed to fetch messages: ${data.error}`);
      }
    } catch (error) {
      console.error('Error fetching messages', error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/sget/users`);
      const data = await response.json();
      if (data.success) {
        setUsers(data.users);
      } else {
        console.error(`Failed to fetch users: ${data.error}`);
      }
    } catch (error) {
      console.error('Error fetching users', error);
    }
  };


  useEffect(() => {
    fetchMessages();
    fetchUsers();

    const storedUser = getWithExpiry('selectedUser');
    if (storedUser) {
      setSelectedUser(storedUser);
    }

  }, []);

  const handleUserChange = (event) => {
    const value = event.target.value;
    setSelectedUser(value);
    setWithExpiry('selectedUser', value, 30 * 60 * 1000);
  };

  const userMap = {};
  users.forEach((user) => {
    userMap[user.id] = `${user.first_name} ${user.last_name}`;
  });


  const filteredMessages = messages.filter((msg) => {
    let userMatch = true;
    if (selectedUser) {
      userMatch = msg.human_id === selectedUser;
    }

    return userMatch;
  });

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4" component="h1" sx={{ flexGrow: 1 }}>
          User Messages
        </Typography>
        <IconButton onClick={fetchMessages} color="primary">
          <RefreshIcon />
        </IconButton>
      </Box>

      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="user-select-label">User</InputLabel>
          <Select
            labelId="user-select-label"
            value={selectedUser}
            label="User"
            onChange={handleUserChange}
          >
            <MenuItem value="">
              <em>All Users</em>
            </MenuItem>
            {users.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.first_name} {user.last_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
        <Table stickyHeader aria-label="messages table">
          <TableHead>
            <TableRow>
              <TableCell>Message ID</TableCell>
              <TableCell>Human ID</TableCell>
              <TableCell>Task ID</TableCell>
              <TableCell>Message</TableCell>
              <TableCell>Channel</TableCell>
              <TableCell>Timestamp</TableCell>
              <TableCell>AI Sender</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredMessages.map((msg) => (
              <TableRow key={msg.id} hover>
                <TableCell>
                  <Tooltip title={msg.id}>
                    <span>{msg.id.substring(0, 8)}...</span>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title={msg.human_id}>
                    <span>{userMap[msg.human_id] || msg.human_id.substring(0, 8) + '...'}</span>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title={msg.task_id}>
                    <span>{msg.task_id.substring(0, 8) + '...'}</span>
                  </Tooltip>
                </TableCell>
                <TableCell sx={{ maxWidth: 400, whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                  {msg.message}
                </TableCell>
                <TableCell>{msg.channel}</TableCell>
                <TableCell>{formatDateToEDT(msg.timestamp)}</TableCell>
                <TableCell>{msg.ai_sender ? 'Yes' : 'No'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default Messages;
