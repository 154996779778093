import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Grid,
  Paper,
  CircularProgress,
  Alert,
  Box,
  Snackbar,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import ScatterPlot from './ScatterPlot'; // Import the ScatterPlot component

function AppMetrics() {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [metrics, setMetrics] = useState({
    MAU: 0,
    DAU: 0,
    costPerUser: 0,
    userGrowthRate: 0,
    taskStates: {},
    channelTaskStates: {},
    interactions: {},
    interactions30Days: {},
    churnRate: 0,
    channels: [],
    taskStatesList: [],
  });
  const [latencies, setLatencies] = useState({});
  const [latencyAverages, setLatencyAverages] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const API_BASE_URL = 'https://bonushealth.co/api';

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/sget/tasks`);
        const data = await response.json();
        if (data.success) {
          setTasks(data.tasks || []);
        } else {
          setError(data.error || 'Failed to fetch tasks.');
        }
      } catch (err) {
        console.error('Error fetching tasks:', err);
        setError('An error occurred while fetching tasks.');
      } finally {
        setLoading(false);
      }
    };

    const fetchLatencies = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/sget_latencies`);
        const data = await response.json();
        if (data.success) {
          setLatencies(data.latencies);
          setSnackbarMessage('Latencies data fetched successfully.');
          setSnackbarOpen(true);
        } else {
          setError(data.error || 'Failed to fetch latencies.');
          setSnackbarMessage(`Error: ${data.error}`);
          setSnackbarOpen(true);
        }
      } catch (err) {
        console.error('Error fetching latencies:', err);
        setError('An error occurred while fetching latencies.');
        setSnackbarMessage('An error occurred while fetching latencies.');
        setSnackbarOpen(true);
      }
    };

    fetchTasks();
    fetchLatencies();
  }, []);

  useEffect(() => {
    if (tasks.length > 0) {
      computeMetrics();
    }
  }, [tasks]);

  useEffect(() => {
    if (latencies && Object.keys(latencies).length > 0) {
      computeLatencyAverages(latencies);
    }
  }, [latencies]);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const computeMetrics = () => {
    const now = moment();
    const usersSet = new Set();
    const newUsersSet = new Set();
    const dailyUsersSet = new Set();
    const userLastActive = {};
    const userInteractions = {};
    const taskStates = {};
    const channelTaskStates = {};
    let totalCost = 0;

    // Collect all unique channels and task states
    const channelsSet = new Set();
    const taskStatesSet = new Set();

    tasks.forEach((task) => {
      const state = task.state;
      const channel = task.default_channel || 'Unknown';

      channelsSet.add(channel);
      taskStatesSet.add(state);
    });

    // Initialize interactions and interactions30Days objects dynamically
    const interactions = {};
    const interactions30Days = {};
    channelsSet.forEach((channel) => {
      interactions[channel] = 0;
      interactions30Days[channel] = 0;
    });

    // Initialize taskStates and channelTaskStates dynamically
    taskStatesSet.forEach((state) => {
      taskStates[state] = 0;
    });

    channelsSet.forEach((channel) => {
      channelTaskStates[channel] = {};
      taskStatesSet.forEach((state) => {
        channelTaskStates[channel][state] = 0;
      });
    });

    tasks.forEach((task) => {
      const userId = task.subject_id;
      const lastUpdated = moment(task.last_update);
      const createdAt = moment(task.created);
      const daysSinceLastUpdate = now.diff(lastUpdated, 'days');
      const daysSinceCreation = now.diff(createdAt, 'days');
      const channel = task.default_channel || 'Unknown';
      const state = task.state;

      usersSet.add(userId);

      // Monthly Active Users (MAU)
      if (daysSinceLastUpdate <= 30) {
        userLastActive[userId] = lastUpdated;
      }

      // Daily Active Users (DAU) - average over past week
      if (daysSinceLastUpdate <= 7) {
        dailyUsersSet.add(userId);
      }

      // Interaction Volume
      if (interactions[channel] !== undefined) {
        interactions[channel]++;
      }

      // Interaction Volume in past 30 days
      if (daysSinceLastUpdate <= 30 && interactions30Days[channel] !== undefined) {
        interactions30Days[channel]++;
      }

      // Initialize userInteractions object for the user if not present
      if (!userInteractions[userId]) {
        userInteractions[userId] = {};
        channelsSet.forEach((ch) => {
          userInteractions[userId][ch] = 0;
        });
      }

      // Increment user interactions for the given channel
      if (userInteractions[userId][channel] !== undefined) {
        userInteractions[userId][channel]++;
      }

      // Task States
      if (taskStates[state] !== undefined) {
        taskStates[state]++;
      }

      // Task States by Channel
      if (channelTaskStates[channel][state] !== undefined) {
        channelTaskStates[channel][state]++;
      }

      // User Growth Rate
      if (daysSinceCreation <= 7) {
        newUsersSet.add(userId);
      }
    });

    // Churn Rate
    const inactiveUsers = [...usersSet].filter((userId) => {
      const lastActive = userLastActive[userId];
      if (lastActive) {
        return now.diff(lastActive, 'days') > 30;
      }
      return true;
    });

    // Calculate Costs
    Object.keys(userInteractions).forEach((userId) => {
      const userChannels = userInteractions[userId];
      let userCost = 0;

      Object.keys(userChannels).forEach((channel) => {
        // Define cost per interaction for each channel
        let costPerInteraction = 0;
        if (channel === 'RetellWrapper') {
          costPerInteraction = 0.3;
        } else if (channel === 'SMSClient') {
          costPerInteraction = 0.1;
        } else if (channel === 'EmailClient') {
          costPerInteraction = 0.01;
        } else {
          costPerInteraction = 0.05; // Default cost for unknown channels
        }
        userCost += (userChannels[channel] || 0) * costPerInteraction;
      });

      totalCost += userCost;
    });

    const costPerUser = totalCost / Object.keys(userInteractions).length || 0;

    // Daily Active Users (average over past week)
    const dailyActiveUsers = dailyUsersSet.size / 7;

    setMetrics({
      MAU: Object.keys(userLastActive).length,
      DAU: dailyActiveUsers.toFixed(2),
      costPerUser: costPerUser.toFixed(2),
      userGrowthRate: ((newUsersSet.size / usersSet.size) * 100).toFixed(2),
      taskStates,
      channelTaskStates,
      interactions,
      interactions30Days,
      churnRate: ((inactiveUsers.length / usersSet.size) * 100).toFixed(2),
      channels: Array.from(channelsSet),
      taskStatesList: Array.from(taskStatesSet),
    });
  };

  const computeLatencyAverages = (latencies) => {
    const values = {
      min: [],
      max: [],
      p50: [],
      p90: [],
      p95: [],
      p99: [],
    };

    Object.keys(latencies).forEach((programName) => {
      latencies[programName].forEach((item) => {
        ['min', 'max', 'p50', 'p90', 'p95', 'p99'].forEach((key) => {
          if (item.e2e_latency[key]) {
            values[key].push(item.e2e_latency[key]);
          }
        });
      });
    });

    const averages = {};
    ['min', 'max', 'p50', 'p90', 'p95', 'p99'].forEach((key) => {
      const total = values[key].reduce((acc, val) => acc + val, 0);
      const avg = total / values[key].length;
      averages[key] = avg;
    });

    setLatencyAverages(averages);
  };

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF00FF', '#800000'];

  const parseLatencyData = (latencies) => {
    const data = [];

    if (latencies && Object.keys(latencies).length > 0) {
      Object.keys(latencies).forEach((programName) => {
        latencies[programName].forEach((item) => {
          ['max', 'min', 'p50', 'p90', 'p95', 'p99'].forEach((key) => {
            if (item.e2e_latency[key] && item.llm_latency[key] && item.duration_millis) {
              // Assign unique color based on the key
              let color;
              switch (key) {
                case 'max':
                  color = 'red';
                  break;
                case 'min':
                  color = 'blue';
                  break;
                case 'p50':
                  color = 'green';
                  break;
                case 'p90':
                  color = 'yellow';
                  break;
                case 'p95':
                  color = 'purple';
                  break;
                case 'p99':
                  color = 'orange';
                  break;
                default:
                  color = 'gray';
              }

              // Round values for consistency
              const e2eLatency = Math.round(item.e2e_latency[key] * 100) / 100 / 1000;
              const llmLatency = Math.round((item.llm_latency[key] / item.e2e_latency[key]) * 100) / 100;
              const duration = item.duration_millis / 1000 / 60;

              data.push({
                x: e2eLatency, // e2e latency for x-axis
                y: llmLatency, // ratio of llm latency to e2e latency for y-axis
                z: duration, // duration for z-axis
                color: color, // Color based on key
                key: key, // Add the key for displaying in the info
              });
            }
          });
        });
      });
    }

    return data;
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        App Metrics Dashboard
      </Typography>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : (
        <Grid container spacing={3} sx={{ mt: 2 }}>
          {/* Metrics Cards */}
          {[
            {
              title: 'Monthly Active Users (MAU)',
              value: metrics.MAU,
            },
            {
              title: 'Daily Active Users (DAU) (Avg over past week)',
              value: metrics.DAU,
            },
            {
              title: 'Average Cost per User (Past 30 days)',
              value: `$${metrics.costPerUser}`,
            },
            {
              title: 'User Growth Rate (Past 7 days)',
              value: `${metrics.userGrowthRate}%`,
            },
            {
              title: 'Churn Rate',
              value: `${metrics.churnRate}%`,
            },
          ].map((metric, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6">{metric.title}</Typography>
                <Typography variant="h4">{metric.value}</Typography>
              </Paper>
            </Grid>
          ))}

          {/* Interaction Volume */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6">Total Interaction Volume</Typography>
              {metrics.channels.map((channel) => (
                <Typography variant="body1" key={channel}>
                  {channel}: {metrics.interactions[channel]}
                </Typography>
              ))}
              <Typography variant="h6" sx={{ mt: 2 }}>
                Interaction Volume (Past 30 days)
              </Typography>
              {metrics.channels.map((channel) => (
                <Typography variant="body1" key={channel}>
                  {channel}: {metrics.interactions30Days[channel]}
                </Typography>
              ))}
            </Paper>
          </Grid>

          {/* Task States Distribution */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Task States Distribution
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={metrics.taskStatesList.map((state, index) => ({
                      name: state,
                      value: metrics.taskStates[state],
                    }))}
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    fill="#8884d8"
                    label
                  >
                    {metrics.taskStatesList.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>

          {/* Task States by Channel */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Task States by Channel
              </Typography>
              <Grid container spacing={3}>
                {metrics.channels.map((channel) => (
                  <Grid item xs={12} sm={6} md={4} key={channel}>
                    <Box sx={{ mb: 2 }}>
                      <Typography variant="subtitle1" align="center" gutterBottom>
                        {channel}
                      </Typography>
                      <ResponsiveContainer width="100%" height={200}>
                        <PieChart>
                          <Pie
                            dataKey="value"
                            isAnimationActive={false}
                            data={metrics.taskStatesList.map((state, index) => ({
                              name: state,
                              value: metrics.channelTaskStates[channel][state],
                            }))}
                            cx="50%"
                            cy="50%"
                            outerRadius={60}
                            fill="#82ca9d"
                            label
                          >
                            {metrics.taskStatesList.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                          </Pie>
                          <Tooltip />
                          <Legend />
                        </PieChart>
                      </ResponsiveContainer>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </Grid>

          {/* Latencies Chart */}
          <Grid item xs={12} md={12}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Latency Averages
              </Typography>
              <Grid container spacing={2}>
                {['min', 'max', 'p50', 'p90', 'p95', 'p99'].map((key) => (
                  <Grid item xs={6} md={4} key={key}>
                    <Typography variant="body1">
                      {key.toUpperCase()}: {latencyAverages[key] ? `${(latencyAverages[key] / 1000).toFixed(2)} s` : 'N/A'}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
                Latency Scatter Plot
              </Typography>
              <ScatterPlot data={parseLatencyData(latencies)} />
            </Paper>
          </Grid>
        </Grid>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        action={
          <IconButton size="small" color="inherit" onClick={handleCloseSnackbar}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Container>
  );
}

export default AppMetrics;
