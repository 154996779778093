// src/components/Tasks.js

import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  MenuItem,
  Button,
  Grid,
  Box,
  Alert,
  CircularProgress,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from '@mui/material';
import { formatInTimeZone } from 'date-fns-tz';
import { useSearchParams } from 'react-router-dom'; // Keep this import
import UserAutocomplete from './UserAutocomplete';
import TaskFlowChart from './TaskFlowChart'; // Import the new component

function Tasks({ handleTabChange }) {
  const [userId, setUserId] = useState(null);
  const [users, setUsers] = useState([]);
  const [taskId, setTaskId] = useState('');
  const [tasks, setTasks] = useState([]);
  const [artifactData, setArtifactData] = useState([]);
  const [metrics, setMetrics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedLevel, setSelectedLevel] = useState('');
  const [autoFetch, setAutoFetch] = useState(false);
  const [showFlowchart, setShowFlowchart] = useState(false);

  const [showManageTaskModal, setShowManageTaskModal] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [manageTaskTab, setManageTaskTab] = useState('complete');
  const [sendCompletionMessage, setSendCompletionMessage] = useState(false);
  const [addDefaultNextSchedule, setAddDefaultNextSchedule] = useState(false);

  const [selectedTask, setSelectedTask] = useState(null);
  const [showMessagesModal, setShowMessagesModal] = useState(false);
  const [messages, setMessages] = useState([]);

  const API_BASE_URL = 'https://bonushealth.co/api';

  const [searchParams] = useSearchParams(); // Use searchParams

  // Fetch URL parameters and localStorage items
  useEffect(() => {
    // First, check if localStorage items exist
    const storedUserId = localStorage.getItem('tasksUserId');
    const storedTaskId = localStorage.getItem('tasksTaskId');
    const storedAutoFetch = localStorage.getItem('tasksAutoFetch');

    if (storedUserId || storedTaskId || storedAutoFetch) {
      // If localStorage items exist, use them
      if (storedUserId) {
        setUserId(storedUserId);
      }
      if (storedTaskId) {
        setTaskId(storedTaskId);
      }
      if (storedAutoFetch === 'true') {
        setAutoFetch(true);
      }

      // Clear the localStorage items after reading them
      localStorage.removeItem('tasksUserId');
      localStorage.removeItem('tasksTaskId');
      localStorage.removeItem('tasksAutoFetch');
    } else {
      // If no localStorage items, use URL parameters
      const userIdParam = searchParams.get('userId');
      const taskIdParam = searchParams.get('taskId');
      const autoFetchParam = searchParams.get('autoFetch');

      if (userIdParam) {
        setUserId(userIdParam);
      }
      if (taskIdParam) {
        setTaskId(taskIdParam);
      }
      if (autoFetchParam === 'true') {
        setAutoFetch(true);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    const fetchUsersAndMetrics = async () => {
      setSuccessMessage('');
      setErrorMessage('');
      try {
        const [usersResponse, metricsResponse] = await Promise.all([
          fetch(`${API_BASE_URL}/sget/users`),
          fetch(`${API_BASE_URL}/sget/metrics`),
        ]);

        const usersData = await usersResponse.json();
        const metricsData = await metricsResponse.json();

        if (usersData.success) {
          setUsers(usersData.users || []);
          setSuccessMessage('Users fetched successfully.');
        } else {
          setErrorMessage(usersData.error || 'Failed to fetch users.');
        }

        if (metricsData.success) {
          setMetrics(metricsData.metrics || []);
        } else {
          console.error('Failed to fetch metrics:', metricsData.error);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setErrorMessage('An error occurred while fetching data.');
      }
    };

    fetchUsersAndMetrics();
  }, [API_BASE_URL]);

  useEffect(() => {
    const fetchTasks = async () => {
      if (!userId) {
        setTasks([]);
        return;
      }
      setLoading(true);
      setSuccessMessage('');
      setErrorMessage('');
      try {
        const url = `${API_BASE_URL}/sget/tasks?user_id=${userId}`;
        const response = await fetch(url);
        const data = await response.json();
        if (data.success) {
          setTasks(data.tasks || []);
          setSuccessMessage('Tasks fetched successfully.');

          if (autoFetch && taskId) {
            await handleFetchArtifact(data.tasks);
            setAutoFetch(false);
          }
        } else {
          setTasks([]);
          setErrorMessage(data.error || 'Failed to fetch tasks.');
        }
      } catch (error) {
        console.error('Error fetching tasks:', error);
        setErrorMessage('An error occurred while fetching tasks.');
        setTasks([]);
      } finally {
        setLoading(false);
      }
    };

    fetchTasks();
  }, [userId, API_BASE_URL, taskId, autoFetch]);

  const handleUserChange = (newUserId) => {
    setUserId(newUserId);
    setTaskId('');
    setArtifactData([]);
  };

  const handleTaskChange = (event) => {
    setTaskId(event.target.value);
    setArtifactData([]);
  };

  const handleLevelChange = (event) => {
    setSelectedLevel(event.target.value);
  };

  const handleFetchArtifact = async (fetchedTasks = tasks) => {
    if (!taskId) return;

    const selectedTask = fetchedTasks.find((task) => task.id === taskId);

    if (selectedTask) {
      const user_id = selectedTask.subject_id;
      const subfolderParts = selectedTask.subfolder.split('/');

      const program = subfolderParts[0];
      const version = subfolderParts[1];
      const lesson_number = subfolderParts[2];

      const params = new URLSearchParams();
      params.append('version', version);
      params.append('lesson_number', lesson_number);

      setLoading(true);
      setSuccessMessage('');
      setErrorMessage('');
      try {
        const url = `${API_BASE_URL}/sget_artifacts/${user_id}/${program}?${params.toString()}`;
        const response = await fetch(url);
        const data = await response.json();
        if (data.success) {
          setArtifactData(data.results || []);
          setSuccessMessage('Artifacts fetched successfully.');
        } else {
          console.error('Error fetching artifacts:', data.error);
          setErrorMessage(data.error || 'Failed to fetch artifacts.');
        }
      } catch (error) {
        console.error('Error fetching artifacts:', error);
        setErrorMessage('An error occurred while fetching artifacts.');
      } finally {
        setLoading(false);
      }
    }
  };

  const formatDateToEDT = (utcDateStr) => {
    const date = new Date(utcDateStr);
    const timeZone = 'America/New_York';
    return formatInTimeZone(date, timeZone, "yyyy-MM-dd HH:mm 'EDT'");
  };

  const handleNodeClick = async (event, node) => {
    const task = node.data.task;
    setSelectedTask(task);
    setSelectedTaskId(node.id);
    setSuccessMessage('');
    setErrorMessage('');

    if (task.default_channel === 'RetellWrapper') {
      setShowManageTaskModal(true);
      setManageTaskTab('complete');
      setSendCompletionMessage(false);
      setAddDefaultNextSchedule(false);
    } else if (task.default_channel === 'SMSClient') {
      await fetchMessagesForTask(task.id);
      setShowMessagesModal(true);
    }
  };

  const fetchMessagesForTask = async (taskId) => {
    setLoading(true);
    try {
      const url = `${API_BASE_URL}/sget/messages?task_id=${taskId}`;
      const response = await fetch(url);
      const data = await response.json();
      if (data.success) {
        setMessages(data.messages);
      } else {
        console.error('Failed to fetch messages:', data.error);
        setErrorMessage(data.error || 'Failed to fetch messages.');
        setMessages([]);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
      setErrorMessage('An error occurred while fetching messages.');
      setMessages([]);
    } finally {
      setLoading(false);
    }
  };

  const handleCompleteTaskSubmit = async () => {
    setSuccessMessage('');
    setErrorMessage('');
    setLoading(true);
    try {
      const url = `${API_BASE_URL}/scomplete_task/${selectedTaskId}?send_text=${sendCompletionMessage}&schedule_call=${addDefaultNextSchedule}`;
      const response = await fetch(url);
      const data = await response.json();
      if (data.success) {
        setSuccessMessage('Task completed successfully.');
        // Optionally refresh tasks or flowchart
      } else {
        setErrorMessage(data.error || 'Failed to complete task.');
      }
    } catch (error) {
      console.error('Error completing task:', error);
      setErrorMessage('An error occurred while completing the task.');
    } finally {
      setLoading(false);
      setShowManageTaskModal(false);
    }
  };

  const handleSendMessage = async (textType) => {
    setSuccessMessage('');
    setErrorMessage('');
    setLoading(true);
    try {
      const url = `${API_BASE_URL}/ssend_text/${selectedTaskId}?text_type=${textType}`;
      const response = await fetch(url);
      const data = await response.json();
      if (data.success) {
        setSuccessMessage('Message sent successfully.');
      } else {
        setErrorMessage(data.error || 'Failed to send message.');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setErrorMessage('An error occurred while sending the message.');
    } finally {
      setLoading(false);
      setShowManageTaskModal(false);
    }
  };

  const handleRemoveTimeConstraints = async () => {
    setSuccessMessage('');
    setErrorMessage('');
    setLoading(true);
    try {
      const url = `${API_BASE_URL}/sremove_constraints/${selectedTaskId}`;
      const response = await fetch(url);
      const data = await response.json();
      if (data.success) {
        setSuccessMessage('Time constraints removed successfully.');
      } else {
        setErrorMessage(data.error || 'Failed to remove time constraints.');
      }
    } catch (error) {
      console.error('Error removing time constraints:', error);
      setErrorMessage('An error occurred while removing time constraints.');
    } finally {
      setLoading(false);
      setShowManageTaskModal(false);
    }
  };

  const renderArtifact = (artifact) => {
    switch (artifact.filename) {
      case 'instructions.json':
        return renderInstructions(artifact);
      case 'metadata.json':
        return renderMetadata(artifact);
      case 'next_prompt.json':
        return renderNextPrompt(artifact);
      case 'summary.json':
        return renderSummary(artifact);
      case 'task_summary.json':
        return renderTaskSummary(artifact);
      case 'epic_summary.json':
        return renderSummary(artifact);
      case 'transcript.json':
        return renderTranscript(artifact);
      case 'metrics.json':
        return renderMetrics(artifact);
      case 'call_audio.wav':
        return renderCallAudio(artifact);
      case 'metrics_source.json':
        return renderMetricsSource(artifact);
      default:
        return renderGenericArtifact(artifact);
    }
  };

  const renderInstructions = (artifact) => (
    <Box sx={{ mb: 2 }}>
      <Typography variant="h6">Instructions</Typography>
      <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
        {artifact.contents}
      </Typography>
    </Box>
  );

  const renderMetadataValue = (value) => {
    if (typeof value === 'object' && value !== null) {
      if (Array.isArray(value)) {
        return (
          <ul>
            {value.map((item, index) => (
              <li key={index}>{renderMetadataValue(item)}</li>
            ))}
          </ul>
        );
      } else {
        return (
          <TableContainer component={Paper} sx={{ mt: 1 }}>
            <Table size="small">
              <TableBody>
                {Object.entries(value).map(([subKey, subValue]) => (
                  <TableRow key={subKey}>
                    <TableCell>{subKey}</TableCell>
                    <TableCell>{renderMetadataValue(subValue)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );
      }
    } else {
      return <Typography variant="body2">{String(value)}</Typography>;
    }
  };

  const renderMetadata = (artifact) => {
    const metadata = artifact.contents.metadata || {};
    const {
      transcript,
      transcript_object,
      transcript_with_tool_calls,
      ...restMetadata
    } = metadata;

    return (
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6">Metadata</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {Object.entries(restMetadata).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell>{key}</TableCell>
                  <TableCell>{renderMetadataValue(value)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  const renderNextPrompt = (artifact) => {
    const { completed, intro, prompt } = artifact.contents;
    return (
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6">Next Prompt</Typography>
        <Typography variant="body1">
          <strong>Completed:</strong> {completed ? 'Yes' : 'No'}
        </Typography>
        <Typography variant="body1">
          <strong>Intro:</strong>{' '}
          <span style={{ whiteSpace: 'pre-wrap' }}>{intro}</span>
        </Typography>
        <Typography variant="body1">
          <strong>Prompt:</strong>{' '}
          <span style={{ whiteSpace: 'pre-wrap' }}>{prompt}</span>
        </Typography>
      </Box>
    );
  };

  const renderSummary = (artifact) => {
    const { summary } = artifact.contents;
    return (
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6">Summary</Typography>
        <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
          {summary}
        </Typography>
      </Box>
    );
  };

  const renderTaskSummary = (artifact) => {
    const { summary, metrics: artifactMetrics, numeric } = artifact.contents;

    return (
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6">Task Outputs</Typography>
        {summary && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1">Summary</Typography>
            <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
              {summary}
            </Typography>
          </Box>
        )}

        {artifactMetrics && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1">Metrics</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Data Type</TableCell>
                    <TableCell>Value</TableCell>
                    <TableCell>Numeric</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(artifactMetrics).map(([metricName, value]) => {
                    const metricDetail = metrics.find(
                      (m) => m.name === metricName
                    ) || {};
                    return (
                      <TableRow key={metricName}>
                        <TableCell>{metricName}</TableCell>
                        <TableCell>{metricDetail.description || 'N/A'}</TableCell>
                        <TableCell>{metricDetail.data_type || 'N/A'}</TableCell>
                        <TableCell>
                          {value !== null && value !== undefined ? value : 'N/A'}
                        </TableCell>
                        <TableCell>
                          {numeric && numeric[metricName] !== undefined
                            ? numeric[metricName]
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Box>
    );
  };

  const renderTranscript = (artifact) => {
    const { transcript } = artifact.contents;
    if (!transcript) return null;

    const srtContent = generateSRT(transcript);

    return (
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6">Transcript</Typography>
        <pre style={{ whiteSpace: 'pre-wrap' }}>{srtContent}</pre>
      </Box>
    );
  };

  const generateSRT = (transcript) => {
    let srt = '';
    transcript.forEach((entry, index) => {
      const { role, content, words } = entry;
      let startTime = 0;
      let endTime = 0;

      if (words && words.length > 0) {
        startTime = words[0].start;
        endTime = words[words.length - 1].end;
      }

      srt += `${index + 1}\n`;
      srt += `${formatTime(startTime)} --> ${formatTime(endTime)}\n`;
      srt += `${role.toUpperCase()}: ${content}\n\n`;
    });
    return srt;
  };

  const formatTime = (seconds) => {
    const date = new Date(null);
    date.setSeconds(seconds || 0);
    const isoString = date.toISOString().substr(11, 12);
    return isoString.replace('.', ',');
  };

  const renderMetrics = (artifact) => {
    const { metrics: artifactMetrics } = artifact.contents;
    if (!artifactMetrics) return null;

    return (
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6">Metrics</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Data Type</TableCell>
                <TableCell>Value</TableCell>
                <TableCell>Numeric</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(artifactMetrics).map(([metricName, value]) => {
                const metricDetail = metrics.find((m) => m.name === metricName) || {};
                return (
                  <TableRow key={metricName}>
                    <TableCell>{metricName}</TableCell>
                    <TableCell>{metricDetail.description || 'N/A'}</TableCell>
                    <TableCell>{metricDetail.data_type || 'N/A'}</TableCell>
                    <TableCell>
                      {value !== null && value !== undefined ? value : 'N/A'}
                    </TableCell>
                    <TableCell>N/A</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  const renderCallAudio = (artifact) => {
    const audioUrl = artifact.contents;
    return (
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6">Call Audio</Typography>
        <audio controls src={audioUrl} />
      </Box>
    );
  };

  const renderMetricsSource = (artifact) => (
    <Box sx={{ mb: 2 }}>
      <Typography variant="h6">Metrics Source</Typography>
      <pre style={{ whiteSpace: 'pre-wrap' }}>
        {JSON.stringify(artifact.contents, null, 2)}
      </pre>
    </Box>
  );

  const renderGenericArtifact = (artifact) => (
    <Box sx={{ mb: 2 }}>
      <Typography variant="h6">{artifact.filename}</Typography>
      <pre style={{ whiteSpace: 'pre-wrap' }}>
        {JSON.stringify(artifact.contents, null, 2)}
      </pre>
    </Box>
  );

  const groupArtifactsByDate = (artifacts, selectedLevel) => {
    const filteredArtifacts = selectedLevel
      ? artifacts.filter((artifact) => artifact.level === selectedLevel)
      : artifacts;

    // Group artifacts by timestamp
    const groups = {};

    filteredArtifacts.forEach((artifact) => {
      const date = artifact.date ? new Date(artifact.date) : null;
      const timestamp = date ? date.getTime() : 0; // use 0 for 'N/A' date
      if (!groups[timestamp]) {
        groups[timestamp] = {
          date: date,
          artifacts: [],
        };
      }
      groups[timestamp].artifacts.push(artifact);
    });

    // Convert groups to array and sort by timestamp
    const groupArray = Object.values(groups).sort((a, b) => a.date - b.date);

    return groupArray;
  };

  const sortArtifacts = (artifacts) => {
    const preEstablishedOrder = [
      'summary.json',
      'task_summary.json',
      'epic_summary.json',
      'call_audio.wav',
      'transcript.json',
      'metrics.json',
      'next_prompt.json',
      'instructions.json',
      'metadata.json',
      'metrics_source.json',
      // any other filenames that may appear
    ];

    return artifacts.sort((a, b) => {
      const aIndex = preEstablishedOrder.indexOf(a.filename);
      const bIndex = preEstablishedOrder.indexOf(b.filename);
      if (aIndex === -1 && bIndex === -1) {
        return a.filename.localeCompare(b.filename);
      }
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
      return aIndex - bIndex;
    });
  };

  const groupedArtifacts = groupArtifactsByDate(artifactData, selectedLevel);

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Tasks
      </Typography>

      {successMessage && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {successMessage}
        </Alert>
      )}

      {errorMessage && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {errorMessage}
        </Alert>
      )}

      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          {/* User ID Dropdown */}
          <Grid item xs={12} sm={4}>
            <UserAutocomplete
              users={users}
              userId={userId}
              onUserChange={handleUserChange}
              label="Select User"
              fullWidth
            />
          </Grid>

          {/* Task Dropdown */}
          <Grid item xs={12} sm={4}>
            <TextField
              select
              label="Select Task"
              value={taskId}
              onChange={handleTaskChange}
              fullWidth
            >
              {tasks.map((task) => (
                <MenuItem key={task.id} value={task.id}>
                  {task.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Level Dropdown */}
          <Grid item xs={12} sm={4}>
            <TextField
              select
              label="Select Level"
              value={selectedLevel}
              onChange={handleLevelChange}
              fullWidth
            >
              <MenuItem value="">
                <em>All Levels</em>
              </MenuItem>
              <MenuItem value="interaction">Interaction</MenuItem>
              <MenuItem value="task">Task</MenuItem>
              <MenuItem value="epic">Epic</MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleFetchArtifact()}
            disabled={!taskId}
            sx={{ mr: 2 }}
          >
            Fetch Artifacts
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setShowFlowchart(!showFlowchart)}
            disabled={!userId || tasks.length === 0}
          >
            {showFlowchart ? 'Hide Tasks Flowchart' : 'Show Tasks Flowchart'}
          </Button>
        </Box>

        {/* Task Flow Chart Component */}
        {userId && tasks.length > 0 && showFlowchart && (
          <TaskFlowChart tasks={tasks} onNodeClick={handleNodeClick} />
        )}

        {/* Manage Task Modal */}
        <Dialog
          open={showManageTaskModal}
          onClose={() => setShowManageTaskModal(false)}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Manage Task (ID {selectedTask ? selectedTask.id : 'N/A'})</DialogTitle>
          <DialogContent>
            <Tabs
              value={manageTaskTab}
              onChange={(event, newValue) => setManageTaskTab(newValue)}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab label="Complete Task" value="complete" />
              <Tab label="Send Message" value="message" />
              <Tab label="Edit" value="edit" />
            </Tabs>
            {manageTaskTab === 'complete' && (
              <Box sx={{ mt: 2 }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addDefaultNextSchedule}
                        onChange={(e) =>
                          setAddDefaultNextSchedule(e.target.checked)
                        }
                      />
                    }
                    label="Add default next schedule"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sendCompletionMessage}
                        onChange={(e) =>
                          setSendCompletionMessage(e.target.checked)
                        }
                      />
                    }
                    label="Send completion message"
                  />
                </FormGroup>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCompleteTaskSubmit}
                  sx={{ mt: 2 }}
                >
                  Submit
                </Button>
              </Box>
            )}
            {manageTaskTab === 'message' && (
              <Box sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleSendMessage('completion')}
                  sx={{ mr: 2 }}
                >
                  Completion
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleSendMessage('missed_call')}
                  sx={{ mr: 2 }}
                >
                  Missed Call
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleSendMessage('missed_session')}
                >
                  Missed Lesson
                </Button>
              </Box>
            )}
            {manageTaskTab === 'edit' && (
              <Box sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleRemoveTimeConstraints}
                >
                  Remove time constraints
                </Button>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowManageTaskModal(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* Messages Modal */}
        <Dialog
          open={showMessagesModal}
          onClose={() => setShowMessagesModal(false)}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>
            Messages for Task (ID {selectedTask ? selectedTask.id : 'N/A'})
          </DialogTitle>
          <DialogContent>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <CircularProgress />
              </Box>
            ) : messages.length > 0 ? (
              <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
                <Table stickyHeader aria-label="messages table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Message ID</TableCell>
                      <TableCell>Human ID</TableCell>
                      <TableCell>Task ID</TableCell>
                      <TableCell>Message</TableCell>
                      <TableCell>Channel</TableCell>
                      <TableCell>Timestamp</TableCell>
                      <TableCell>AI Sender</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {messages.map((msg) => (
                      <TableRow key={msg.id} hover>
                        <TableCell>
                          <Tooltip title={msg.id}>
                            <span>{msg.id.substring(0, 8)}...</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Tooltip title={msg.human_id}>
                            <span>{msg.human_id.substring(0, 8)}...</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Tooltip title={msg.task_id}>
                            <span>{msg.task_id.substring(0, 8)}...</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          sx={{
                            maxWidth: 400,
                            whiteSpace: 'pre-wrap',
                            wordWrap: 'break-word',
                          }}
                        >
                          {msg.message}
                        </TableCell>
                        <TableCell>{msg.channel}</TableCell>
                        <TableCell>{formatDateToEDT(msg.timestamp)}</TableCell>
                        <TableCell>{msg.ai_sender ? 'Yes' : 'No'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="body1">
                No messages found for this task.
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowMessagesModal(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <CircularProgress />
          </Box>
        )}

        {artifactData.length > 0 && (
          <Box sx={{ mt: 4 }}>
            {groupedArtifacts.map((group, index) => {
              const sortedArtifacts = sortArtifacts(group.artifacts);
              return (
                <Accordion key={index}>
                  <AccordionSummary>
                    <Chip
                      label={
                        group.date
                          ? `Interaction: ${group.date.toLocaleString()}`
                          : 'Task Summary'
                      }
                      color="primary"
                      sx={{ mb: 2 }}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    {sortedArtifacts.map((artifact) => (
                      <Box key={artifact.filename}>{renderArtifact(artifact)}</Box>
                    ))}
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Box>
        )}
      </Box>
    </Container>
  );
}

export default Tasks;
