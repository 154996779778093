import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Grid,
  TextField,
  MenuItem,
  Button,
  Box,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  ListItemText,
  Tooltip,
  Autocomplete,
} from '@mui/material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import AdminAddUser from './AdminAddUser';
import { useNavigate } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import MetricsPopup from './MetricsPopup';
import UserAutocomplete from './UserAutocomplete';

function Users() {
  const [users, setUsers] = useState([]);
  const [courses, setCourses] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [metricsData, setMetricsData] = useState([]);
  const [diagnostics, setDiagnostics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editedUser, setEditedUser] = useState({});
  const [loadingDiagnostics, setLoadingDiagnostics] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [groupDialogOpen, setGroupDialogOpen] = useState(false);
  const [blastDialogOpen, setBlastDialogOpen] = useState(false);
  const [programDialogOpen, setProgramDialogOpen] = useState(false);
  const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [selectedGroupUsers, setSelectedGroupUsers] = useState([]);
  const [blastMessage, setBlastMessage] = useState('');
  const [selectedProgramGroup, setSelectedProgramGroup] = useState('');
  const [selectedBlastGroup, setSelectedBlastGroup] = useState('');
  const [programCourseId, setProgramCourseId] = useState('');
  const [addTimeLimits, setAddTimeLimits] = useState(false);
  const [startTimestamp, setStartTimestamp] = useState(null);

  const [metrics, setMetrics] = useState([]);
  const [includeMetrics, setIncludeMetrics] = useState({});
  const [requiredMetrics, setRequiredMetrics] = useState({});
  const [showMetricModal, setShowMetricModal] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});

  const genders = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    { value: 'Non-binary', label: 'Non-binary' },
    { value: 'Other', label: 'Other' },
  ];

  const timezones = [
    { value: 'America/New_York', label: 'Eastern Time (US & Canada)' },
    { value: 'America/Chicago', label: 'Central Time (US & Canada)' },
    { value: 'America/Denver', label: 'Mountain Time (US & Canada)' },
    { value: 'America/Los_Angeles', label: 'Pacific Time (US & Canada)' },
    { value: 'Europe/London', label: 'London' },
    { value: 'Europe/Paris', label: 'Paris' },
    { value: 'Asia/Tokyo', label: 'Tokyo' },
    { value: 'Asia/Singapore', label: 'Singapore' },
    { value: 'Australia/Sydney', label: 'Sydney' },
    { value: 'UTC', label: 'UTC' },
  ];

  const API_BASE_URL = 'https://bonushealth.co/api';
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch users
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/sget/users`);
        const data = await response.json();
        if (data.success) {
          setUsers(data.users || []);
        } else {
          setError(data.error || 'Failed to fetch users.');
        }
      } catch (err) {
        console.error('Error fetching users:', err);
        setError('An error occurred while fetching users.');
      }
    };

    // Fetch courses
    const fetchCourses = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/sget/courses`);
        const data = await response.json();
        if (data.success) {
          setCourses(data.courses || []);
        } else {
          setError(data.error || 'Failed to fetch courses.');
        }
      } catch (err) {
        console.error('Error fetching courses:', err);
        setError('An error occurred while fetching courses.');
      }
    };

    // Fetch user groups
    const fetchUserGroups = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/sget/user_groups`);
        const data = await response.json();
        if (data.success) {
          const groups = data.user_groups.map((group) => ({
            ...group,
            user_ids: group.user_ids ? group.user_ids.split(',') : [],
          }));
          setUserGroups(groups || []);
        } else {
          setError(data.error || 'Failed to fetch user groups.');
        }
      } catch (err) {
        console.error('Error fetching user groups:', err);
        setError('An error occurred while fetching user groups.');
      }
    };

    // Fetch metrics
    const fetchMetrics = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/sget/metrics`);
        const data = await response.json();
        if (data.success) {
          setMetrics(data.metrics || []);
          // Initialize includeMetrics state
          const initialIncludeMetrics = {};
          data.metrics.forEach((metric) => {
            initialIncludeMetrics[metric.id] = true; // By default all included
          });
          setIncludeMetrics(initialIncludeMetrics);

          // Initialize requiredMetrics state
          const initialRequiredMetrics = {};
          data.metrics.forEach((metric) => {
            initialRequiredMetrics[metric.id] = false; // By default none required
          });
          setRequiredMetrics(initialRequiredMetrics);
        } else {
          console.error('Failed to fetch metrics:', data.error);
        }
      } catch (err) {
        console.error('Error fetching metrics:', err);
        setError('An error occurred while fetching metrics.');
      }
    };

    fetchUsers();
    fetchCourses();
    fetchUserGroups();
    fetchMetrics();
  }, []);

  const handleSaveUser = async () => {
    if (Object.keys(fieldErrors).length > 0) {
      setError('Please fix the validation errors before saving.');
      return;
    }
    setLoading(true);
    setError('');
    setSuccess('');
    try {
      const response = await fetch(`/api/sedit/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: currentUser.id,
          ...editedUser,
        }),
      });

      const result = await response.json();
      if (result.success) {
        setSuccess('User information updated successfully');
        // Update the user in the users array
        const updatedUsers = users.map((user) => {
          if (user.id === currentUser.id) {
            return { ...user, ...editedUser };
          }
          return user;
        });
        setUsers(updatedUsers);
        setEditedUser({});
      } else {
        setError(`Error updating user: ${result.error}`);
      }
    } catch (error) {
      setError('An error occurred while updating the user');
    } finally {
      setLoading(false);
    }
  };

  const handleUserChange = (newUserId) => {
    setSelectedUser(newUserId);
    setMetricsData([]);
    setDiagnostics([]);
    setError('');
    setSuccess('');
    setEditedUser({});
    setFieldErrors({});
  };

  const handleCourseChange = (event) => {
    const course = courses.find((c) => c.id === event.target.value);
    setSelectedCourse(course);
    setMetricsData([]);
    setError('');
  };

  const handleGetMetrics = async () => {
    if (!selectedUser || !selectedCourse) {
      setError('Please select both a user and a course.');
      return;
    }

    setLoading(true);
    setError('');
    setSuccess('');
    setMetricsData([]);

    try {
      const response = await fetch(
        `${API_BASE_URL}/sget_artifacts/${selectedUser}/${selectedCourse.subfolder}?entity=task`
      );
      const data = await response.json();
      if (data.success) {
        const tasks = data.results || [];
        const chartData = [];

        tasks.sort(
          (a, b) => new Date(a.contents.date) - new Date(b.contents.date)
        );

        tasks.forEach((task) => {
          const numericMetrics = task.contents && task.contents.numeric;
          if (numericMetrics) {
            const parsedDate = new Date(task.contents.date);

            const formattedDate =
              parsedDate.toLocaleDateString('en-US', {
                month: 'numeric',
                day: 'numeric',
              }) +
              ', ' +
              parsedDate.toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              });

            const dataPoint = { name: formattedDate };

            Object.keys(numericMetrics).forEach((key) => {
              const value = numericMetrics[key];
              dataPoint[key] = value;
            });

            chartData.push(dataPoint);
          }
        });

        setMetricsData(chartData);
        if (chartData.length === 0) {
          setError('No metrics data available for the selected user and course.');
        }
      } else {
        setError(data.error || 'Failed to fetch metrics.');
      }
    } catch (err) {
      console.error('Error fetching metrics:', err);
      setError('An error occurred while fetching metrics.');
    } finally {
      setLoading(false);
    }
  };

  const handleCheckUser = async () => {
    if (!selectedUser) {
      setError('Please select a user.');
      return;
    }
    setLoadingDiagnostics(true);
    setError('');
    setSuccess('');
    setDiagnostics([]);

    try {
      const response = await fetch(
        `${API_BASE_URL}/scheck_user/${selectedUser}`
      );
      const data = await response.json();
      if (data.success) {
        setDiagnostics(data.diagnostics || []);
      } else {
        setError(data.error || 'Failed to fetch diagnostics.');
      }
    } catch (err) {
      console.error('Error fetching diagnostics:', err);
      setError('An error occurred while fetching diagnostics.');
    } finally {
      setLoadingDiagnostics(false);
    }
  };

  const handleGroupCheckboxChange = async (groupId, isChecked) => {
    try {
      if (isChecked) {
        await fetch(
          `${API_BASE_URL}/sadd_group_user/${groupId}/${selectedUser}`
        );
      } else {
        await fetch(
          `${API_BASE_URL}/sremove_group_user/${groupId}/${selectedUser}`
        );
      }
      const updatedGroups = userGroups.map((group) => {
        if (group.id === groupId) {
          let updatedUserIds = new Set(group.user_ids);
          if (isChecked) {
            updatedUserIds.add(selectedUser);
          } else {
            updatedUserIds.delete(selectedUser);
          }
          return {
            ...group,
            user_ids: Array.from(updatedUserIds),
          };
        }
        return group;
      });
      setUserGroups(updatedGroups);
    } catch (err) {
      console.error('Error updating group membership:', err);
      setError('An error occurred while updating group membership.');
    }
  };

  const handleOpenGroupDialog = () => {
    setGroupDialogOpen(true);
    setSelectedGroupUsers([]);
    setNewGroupName('');
  };

  const handleCloseGroupDialog = () => {
    setGroupDialogOpen(false);
  };

  const handleCreateGroup = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/sadd_group`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: newGroupName,
          user_ids: selectedGroupUsers,
        }),
      });
      const data = await response.json();
      if (data.success) {
        setSuccess('Group created successfully.');
        const response = await fetch(`${API_BASE_URL}/sget/user_groups`);
        const data = await response.json();
        if (data.success) {
          const groups = data.user_groups.map((group) => ({
            ...group,
            user_ids: group.user_ids ? group.user_ids.split(',') : [],
          }));
          setUserGroups(groups || []);
        }
        setGroupDialogOpen(false);
      } else {
        setError(data.error || 'Failed to create group.');
      }
    } catch (err) {
      console.error('Error creating group:', err);
      setError('An error occurred while creating group.');
    }
  };

  const handleOpenBlastDialog = () => {
    setBlastDialogOpen(true);
    setBlastMessage('');
    setSelectedBlastGroup('');
  };

  const handleCloseBlastDialog = () => {
    setBlastDialogOpen(false);
  };

  const handleBlastGroup = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/sblast_group_sms`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          group_id: selectedBlastGroup,
          message: blastMessage,
        }),
      });
      const data = await response.json();
      if (data.success) {
        setSuccess('Messages sent successfully.');
        setBlastDialogOpen(false);
      } else {
        setError(data.error || 'Failed to send messages.');
      }
    } catch (err) {
      console.error('Error blasting group:', err);
      setError('An error occurred while sending messages.');
    }
  };

  const handleOpenProgramDialog = () => {
    setProgramDialogOpen(true);
    setAddTimeLimits(false);
    setStartTimestamp(null);
    setProgramCourseId('');
    setSelectedProgramGroup('');
  };

  const handleCloseProgramDialog = () => {
    setProgramDialogOpen(false);
  };

  const handleStartProgram = async () => {
    try {
      const formattedTimestamp = startTimestamp
        ? formatLocalTimestamp(new Date(startTimestamp))
        : null;

      const selectedMetricIds = metrics
        .filter((metric) => includeMetrics[metric.id])
        .map((metric) => metric.id);

      const requiredMetricIds = metrics
        .filter((metric) => requiredMetrics[metric.id])
        .map((metric) => metric.id);

      const response = await fetch(`${API_BASE_URL}/sstart_group_program`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          group_id: selectedProgramGroup,
          course_id: programCourseId,
          add_time_limits: addTimeLimits,
          start_timestamp: formattedTimestamp,
          metric_ids: selectedMetricIds,
          required_metric_ids: requiredMetricIds,
        }),
      });
      const data = await response.json();
      if (data.success) {
        setSuccess('Program started successfully.');
        setProgramDialogOpen(false);
      } else {
        setError(data.error || 'Failed to start program.');
      }
    } catch (err) {
      console.error('Error starting program:', err);
      setError('An error occurred while starting the program.');
    }
  };

  const formatLocalTimestamp = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const handleOpenAddUserDialog = () => {
    setAddUserDialogOpen(true);
  };

  const handleCloseAddUserDialog = () => {
    setAddUserDialogOpen(false);
  };

  const handleMetricModalSubmit = () => {
    setShowMetricModal(false);
  };

  const metricKeys = [];
  metricsData.forEach((dataPoint) => {
    Object.keys(dataPoint).forEach((key) => {
      if (key !== 'name' && !metricKeys.includes(key)) {
        metricKeys.push(key);
      }
    });
  });

  const currentUser = users.find((user) => user.id === selectedUser);

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        User Metrics
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {success && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {success}
        </Alert>
      )}

      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          {/* User Dropdown */}
          <Grid item xs={12} sm={6}>
            <UserAutocomplete
              users={users}
              userId={selectedUser}
              onUserChange={handleUserChange}
              label="Select User"
              fullWidth
            />
          </Grid>

          {/* Course Dropdown */}
          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Select Course"
              value={selectedCourse ? selectedCourse.id : ''}
              onChange={handleCourseChange}
              fullWidth
            >
              {courses.map((course) => (
                <MenuItem key={course.id} value={course.id}>
                  {course.course_name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        {/* Display the selected user's information as a contact card */}
        {currentUser && (
          <Card sx={{ mt: 4 }}>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                {`${currentUser.first_name} ${currentUser.last_name} (ID ${currentUser.id})`}
              </Typography>

              {/* Editable fields */}
              {/* Age Field */}
              <TextField
                label="Age"
                variant="outlined"
                fullWidth
                margin="normal"
                type="number" // Ensures numeric input
                value={
                  editedUser.age !== undefined
                    ? editedUser.age
                    : currentUser.age || ''
                }
                onChange={(e) => {
                  const value = e.target.value;
                  const intValue = parseInt(value, 10);

                  if (isNaN(intValue) || value.includes('.')) {
                    setEditedUser({ ...editedUser, age: '' }); // Reset if invalid
                    setFieldErrors({
                      ...fieldErrors,
                      age: 'Age must be an integer',
                    });
                  } else if (intValue < 0) {
                    setEditedUser({ ...editedUser, age: intValue });
                    setFieldErrors({
                      ...fieldErrors,
                      age: 'Age cannot be negative',
                    });
                  } else {
                    setEditedUser({ ...editedUser, age: intValue });
                    const { age, ...rest } = fieldErrors;
                    setFieldErrors(rest); // Remove age error
                  }
                }}
                inputProps={{ min: 0 }} // Ensure the age is non-negative
                error={fieldErrors.age ? true : false}
                helperText={fieldErrors.age}
              />

              {/* Gender Field */}
              <TextField
                select
                label="Gender"
                variant="outlined"
                fullWidth
                margin="normal"
                value={editedUser.gender || currentUser.gender || ''}
                onChange={(e) =>
                  setEditedUser({ ...editedUser, gender: e.target.value })
                }
                required
                error={fieldErrors.gender ? true : false}
                helperText={fieldErrors.gender}
              >
                {genders.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              {/* Email Field */}
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                margin="normal"
                type="email" // Set the type to email to ensure correct format
                value={editedUser.email || currentUser.email || ''}
                onChange={(e) => {
                  const email = e.target.value;
                  setEditedUser({ ...editedUser, email });
                  // Simple email regex validation
                  const emailRegex = /\S+@\S+\.\S+/;
                  if (!emailRegex.test(email)) {
                    setFieldErrors({
                      ...fieldErrors,
                      email: 'Invalid email address',
                    });
                  } else {
                    const { email, ...rest } = fieldErrors;
                    setFieldErrors(rest);
                  }
                }}
                error={fieldErrors.email ? true : false}
                helperText={fieldErrors.email}
              />

              {/* Phone Number Field */}
              <TextField
                label="Phone Number (format: xxxxxxxxxx)"
                variant="outlined"
                fullWidth
                margin="normal"
                value={
                  editedUser.phone_number !== undefined
                    ? editedUser.phone_number.replace('+1', '')
                    : currentUser.phone_number.replace('+1', '') || ''
                }
                onChange={(e) => {
                  let phoneNumber = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                  setEditedUser({ ...editedUser, phone_number: `+1${phoneNumber}` });
                  if (phoneNumber.length !== 10) {
                    setFieldErrors({
                      ...fieldErrors,
                      phone_number: 'Phone number must be 10 digits',
                    });
                  } else {
                    const { phone_number, ...rest } = fieldErrors;
                    setFieldErrors(rest);
                  }
                }}
                placeholder="1234567890"
                error={fieldErrors.phone_number ? true : false}
                helperText={fieldErrors.phone_number}
              />

              {/* Referral/Doctor Field */}
              <TextField
                label="Referral/Doctor"
                variant="outlined"
                fullWidth
                margin="normal"
                value={
                  editedUser.referral !== undefined
                    ? editedUser.referral
                    : currentUser.referral || ''
                }
                onChange={(e) =>
                  setEditedUser({ ...editedUser, referral: e.target.value })
                }
              />

              {/* Goal Field */}
              <TextField
                label="Goal"
                variant="outlined"
                fullWidth
                margin="normal"
                value={
                  editedUser.goal !== undefined
                    ? editedUser.goal
                    : currentUser.goal || ''
                }
                onChange={(e) =>
                  setEditedUser({ ...editedUser, goal: e.target.value })
                }
                inputProps={{ maxLength: 50 }} // Limit goal text length
              />

              {/* Timezone Field */}
              <TextField
                select
                label="Timezone"
                variant="outlined"
                fullWidth
                margin="normal"
                value={editedUser.timezone || currentUser.timezone || ''}
                onChange={(e) =>
                  setEditedUser({ ...editedUser, timezone: e.target.value })
                }
                required
                error={fieldErrors.timezone ? true : false}
                helperText={fieldErrors.timezone}
              >
                {timezones.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              {/* Alias - Optional */}
              <TextField
                label="Alias (Optional)"
                variant="outlined"
                fullWidth
                margin="normal"
                value={
                  editedUser.alias !== undefined
                    ? editedUser.alias
                    : currentUser.alias || ''
                }
                onChange={(e) =>
                  setEditedUser({ ...editedUser, alias: e.target.value })
                }
              />

              {/* Save Button */}
              <Box sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveUser}
                  disabled={loading}
                >
                  Save
                </Button>
                {loading && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      mt: 2,
                    }}
                  >
                    <CircularProgress size={24} />
                  </Box>
                )}
              </Box>

              {/* User Groups Dropdown */}
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6">Groups:</Typography>
                <FormGroup>
                  {userGroups.map((group) => {
                    const isInGroup = group.user_ids.includes(selectedUser);

                    // Get user names for the tooltip
                    const userNames = group.user_ids
                      .map((userId) => {
                        const user = users.find((u) => u.id === userId);
                        return user
                          ? `${user.first_name} ${user.last_name} ${user.timezone}`
                          : 'Unknown User';
                      })
                      .join(', ');

                    return (
                      <FormControlLabel
                        key={group.id}
                        control={
                          <Checkbox
                            checked={isInGroup}
                            onChange={(e) =>
                              handleGroupCheckboxChange(
                                group.id,
                                e.target.checked
                              )
                            }
                          />
                        }
                        label={
                          <Tooltip title={userNames} arrow>
                            <span>{`${group.name} (${group.user_ids.length})`}</span>
                          </Tooltip>
                        }
                      />
                    );
                  })}
                </FormGroup>
              </Box>

              {/* Display Diagnostics */}
              {diagnostics.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h6">Diagnostics:</Typography>
                  <ul>
                    {diagnostics.map((diagnostic, index) => (
                      <li key={index}>{diagnostic}</li>
                    ))}
                  </ul>
                </Box>
              )}
            </CardContent>
          </Card>
        )}

        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleGetMetrics}
            disabled={!selectedUser || !selectedCourse}
          >
            Get Metrics
          </Button>
        </Box>

        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <CircularProgress />
          </Box>
        )}

        {!loading && metricsData.length > 0 && (
          <Box sx={{ mt: 4 }}>
            {metricKeys.map((metricKey) => (
              <Box key={metricKey} sx={{ mb: 4 }}>
                <Typography variant="h6">{metricKey}</Typography>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={metricsData}>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <RechartsTooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey={metricKey}
                      stroke="#8884d8"
                      dot={false}
                      connectNulls={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </Box>
            ))}
          </Box>
        )}
      </Box>

      {/* Action Buttons */}
      <Box sx={{ mt: 4, display: 'flex', gap: 2 }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleOpenAddUserDialog}
        >
          Add User
        </Button>
        <Button variant="contained" onClick={handleOpenGroupDialog}>
          Add Group
        </Button>
        <Button variant="contained" onClick={handleOpenBlastDialog}>
          Blast Group
        </Button>
        <Button variant="contained" onClick={handleOpenProgramDialog}>
          Start Program
        </Button>
      </Box>

      {/* Add User Dialog */}
      <Dialog open={addUserDialogOpen} onClose={handleCloseAddUserDialog}>
        <DialogTitle>Add User</DialogTitle>
        <DialogContent>
          <AdminAddUser />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddUserDialog}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Add Group Dialog */}
      <Dialog open={groupDialogOpen} onClose={handleCloseGroupDialog}>
        <DialogTitle>Add Group</DialogTitle>
        <DialogContent>
          <TextField
            label="Group Name"
            value={newGroupName}
            onChange={(e) => setNewGroupName(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <Autocomplete
            multiple
            options={users}
            getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
            value={users.filter((user) => selectedGroupUsers.includes(user.id))}
            onChange={(event, newValue) => {
              setSelectedGroupUsers(newValue.map((user) => user.id));
            }}
            renderInput={(params) => (
              <TextField {...params} label="Select Users" placeholder="Users" />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseGroupDialog}>Cancel</Button>
          <Button onClick={handleCreateGroup} variant="contained">
            Create Group
          </Button>
        </DialogActions>
      </Dialog>

      {/* Blast Group Dialog */}
      <Dialog open={blastDialogOpen} onClose={handleCloseBlastDialog}>
        <DialogTitle>Blast Group SMS</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Select Group</InputLabel>
            <Select
              value={selectedBlastGroup}
              onChange={(e) => setSelectedBlastGroup(e.target.value)}
              label="Select Group"
            >
              {userGroups.map((group) => {
                // Get user names for the tooltip
                const userNames = group.user_ids
                  .map((userId) => {
                    const user = users.find((u) => u.id === userId);
                    return user
                      ? `${user.first_name} ${user.last_name} ${user.timezone}`
                      : 'Unknown User';
                  })
                  .join(', ');

                return (
                  <MenuItem key={group.id} value={group.id}>
                    <Tooltip title={userNames} arrow>
                      <span>{`${group.name} (${group.user_ids.length})`}</span>
                    </Tooltip>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            label="Message"
            multiline
            rows={4}
            value={blastMessage}
            onChange={(e) => setBlastMessage(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseBlastDialog}>Cancel</Button>
          <Button onClick={handleBlastGroup} variant="contained">
            Send Blast
          </Button>
        </DialogActions>
      </Dialog>

      {/* Start Program Dialog */}
      <Dialog open={programDialogOpen} onClose={handleCloseProgramDialog}>
        <DialogTitle>Start Program</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Select Group</InputLabel>
            <Select
              value={selectedProgramGroup}
              onChange={(e) => setSelectedProgramGroup(e.target.value)}
              label="Select Group"
            >
              {userGroups.map((group) => {
                // Get user names for the tooltip
                const userNames = group.user_ids
                  .map((userId) => {
                    const user = users.find((u) => u.id === userId);
                    return user
                      ? `${user.first_name} ${user.last_name} ${user.timezone}`
                      : 'Unknown User';
                  })
                  .join(', ');

                return (
                  <MenuItem key={group.id} value={group.id}>
                    <Tooltip title={userNames} arrow>
                      <span>{`${group.name} (${group.user_ids.length})`}</span>
                    </Tooltip>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Select Course</InputLabel>
            <Select
              value={programCourseId}
              onChange={(e) => setProgramCourseId(e.target.value)}
              label="Select Course"
            >
              {courses.map((course) => (
                <MenuItem key={course.id} value={course.id}>
                  {course.course_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={addTimeLimits}
                onChange={(e) => setAddTimeLimits(e.target.checked)}
              />
            }
            label="Add Time Limits"
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label="Start Timestamp"
              value={startTimestamp}
              onChange={(newValue) => setStartTimestamp(newValue)}
              renderInput={(params) => (
                <TextField {...params} fullWidth sx={{ mt: 2 }} />
              )}
            />
          </LocalizationProvider>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setShowMetricModal(true)}
            sx={{ mt: 2 }}
          >
            Select Metrics
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseProgramDialog}>Cancel</Button>
          <Button onClick={handleStartProgram} variant="contained">
            Start Program
          </Button>
        </DialogActions>
      </Dialog>

      {/* Metrics Popup */}
      <MetricsPopup
        open={showMetricModal}
        onClose={() => setShowMetricModal(false)}
        metrics={metrics}
        includeMetrics={includeMetrics}
        setIncludeMetrics={setIncludeMetrics}
        requiredMetrics={requiredMetrics}
        setRequiredMetrics={setRequiredMetrics}
        onSubmit={handleMetricModalSubmit}
      />
    </Container>
  );
}

export default Users;
