import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  MenuItem,
  Box,
  Alert,
} from '@mui/material';

function AdminAddUser() {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    age: '',
    gender: '',
    email: '',
    phone_number: '',
    referral: 'Dr. Bill Simpson',
    goal: '',
    timezone: 'America/New_York', // Set a default timezone
    alias: '',  // Add alias field here
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const genders = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    { value: 'Non-binary', label: 'Non-binary' },
    { value: 'Other', label: 'Other' },
  ];

  const timezones = [
    { value: 'America/New_York', label: 'Eastern Time (US & Canada)' },
    { value: 'America/Chicago', label: 'Central Time (US & Canada)' },
    { value: 'America/Denver', label: 'Mountain Time (US & Canada)' },
    { value: 'America/Los_Angeles', label: 'Pacific Time (US & Canada)' },
    { value: 'Europe/London', label: 'London' },
    { value: 'Europe/Paris', label: 'Paris' },
    { value: 'Asia/Tokyo', label: 'Tokyo' },
    { value: 'Asia/Singapore', label: 'Singapore' },
    { value: 'Australia/Sydney', label: 'Sydney' },
    { value: 'UTC', label: 'UTC' },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validatePhoneNumber = (phoneNumber) => {
    const regex = /^\d{3} \d{3} \d{4}$/;
    return regex.test(phoneNumber);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!validatePhoneNumber(formData.phone_number)) {
      setErrorMessage('Phone number must be in the format: xxx xxx xxxx');
      return;
    }

    // Reset messages
    setSuccessMessage('');
    setErrorMessage('');

    // Convert form data to URL-encoded format
    const urlEncodedData = new URLSearchParams(formData);

    try {
      const response = await fetch('https://bonushealth.co/api/sadd_user/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: urlEncodedData.toString(),
      });

      const result = await response.json();

      if (result.success) {
        setSuccessMessage('User added successfully!');
        setFormData({
          first_name: '',
          last_name: '',
          age: '',
          gender: '',
          email: '',
          phone_number: '',
          referral: 'Dr. Bill Simpson',
          goal: '',
          timezone: 'America/New_York',
          alias: '',  // Reset alias field
        });
      } else {
        setErrorMessage('Failed to add user.');
      }
    } catch (error) {
      console.error('Error adding user:', error);
      setErrorMessage('An error occurred while adding the user.');
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Enter User Information
      </Typography>
      {successMessage && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {successMessage}
        </Alert>
      )}
      {errorMessage && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {errorMessage}
        </Alert>
      )}
      <Box component="form" onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          {/* First Name */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="First Name"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              required
              fullWidth
            />
          </Grid>

          {/* Last Name */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Last Name"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              required
              fullWidth
            />
          </Grid>

          {/* Age */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Age"
              name="age"
              type="number"
              value={formData.age}
              onChange={handleChange}
              required
              fullWidth
              inputProps={{ min: 0 }}
            />
          </Grid>

          {/* Gender */}
          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Gender"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              required
              fullWidth
            >
              {genders.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Timezone */}
          <Grid item xs={12}>
            <TextField
              select
              label="Timezone"
              name="timezone"
              value={formData.timezone}
              onChange={handleChange}
              required
              fullWidth
            >
              {timezones.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Email */}
          <Grid item xs={12}>
            <TextField
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              required
              fullWidth
            />
          </Grid>

          {/* Phone Number */}
          <Grid item xs={12}>
            <TextField
              label="Phone Number (format: xxx xxx xxxx)"
              name="phone_number"
              value={formData.phone_number}
              onChange={handleChange}
              required
              fullWidth
              placeholder="123 456 7890"
            />
          </Grid>

          {/* Referral */}
          <Grid item xs={12}>
            <TextField
              label="Who is your doctor?"
              name="referral"
              value={formData.referral}
              onChange={handleChange}
              required
              fullWidth
            />
          </Grid>

          {/* North Star */}
          <Grid item xs={12}>
            <TextField
              label="What goal motivates you? (3-5 words max)"
              name="goal"
              value={formData.goal}
              onChange={handleChange}
              required
              fullWidth
              inputProps={{ maxLength: 100 }}
            />
          </Grid>

          {/* Alias - Optional */}
          <Grid item xs={12}>
            <TextField
              label="Alias (Optional)"
              name="alias"
              value={formData.alias}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              size="large"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default AdminAddUser;
